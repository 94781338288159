import React from 'react';

export default function Nav() {
  return (
    <div>
      <div className="bg-[#1E1E1E] h-20 relative">
        <img
          src="/assets/logo/EBMLogo.png"
          alt="Logo"
          className="w-20 h-19 absolute top-0 left-0"
        />
        <div className="absolute top-0 right-0 flex items-center h-full space-x-4 pr-4">
          <a href="https://github.com/pgm-eliabell" target="_blank" rel="noopener noreferrer">
            <img src="/assets/logo/LinkLogo/github.png" alt="GitHub Logo" className="h-8" />
          </a>
          <a href="https://www.linkedin.com/in/eliaz-medrano-935a9a292/" target="_blank" rel="noopener noreferrer">
            <img src="/assets/logo/LinkLogo/linkedin.png" alt="LinkedIn Logo" className="h-8" />
          </a>
        </div>
      </div>
    </div>
  );
}
