import React from 'react';
import Card from './Card';

const projects = [
  {
    title: "TO-DO App with express.js",
    image: "/assets/CardImages/ToDoCard.png",  // Ensure this path is correct
    description: "A webserver where you can add and remove tasks using a self-made API and database. It includes a login system with verification. The frontend is made with Nunjucks and the backend with Node.js, Knex.js and express.js."
  },
  {
    title: "Mini game: BonkTheDog",
    image: "/assets/CardImages/betterbonk.png",  // Ensure this path is correct
    description: 'One of my first self-made "game" projects. It is a simple game where you have to click on the dog to get points. The game is made with HTML, CSS and JavaScript and includes a tutorial on how to make it.'
  },
  {
    title: "studioClean Website",
    image: "/assets/CardImages/studioclean.png",  // Ensure this path is correct
    description: "A website for a fictional front-end development company. The website is made with Nunjucks, HTML, CSS and JavaScript. The website is responsive and has a clean design with a focus on front-end development."
  },
];

function CardSection() {
  return (
    <div className="p-8 fade-in pt-[6rem]">
      <h2 className="text-3xl font-bold text-center mb-8 text-white">Projects</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {projects.map(project => <Card key={project.title} {...project} />)}
      </div>
    </div>
  );
}

export default CardSection;
