import React, { useRef, useState } from "react";
import "./ContactUs.css";

export default function ContactUs() {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // prevents page reloading
    setLoading(true); // sets loading to true

    const formUrl =
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfgkoBNU459SZcl-wRSSJcNfi2-2bI1DMTIe11V5Xf3JFOPyA/formResponse";

    const formData = new FormData();
    // entry ids for the google form
    formData.append("entry.2005620554", form.name);
    formData.append("entry.1045781291", form.email);
    formData.append("entry.839337160", form.message);

    // sends the form data to the google form
    try {
      await fetch(formUrl, {
        method: "POST",
        mode: "no-cors",
        body: formData, // this is the form data
      });

      setLoading(false);
      // resets the form after submission
      setForm({
        name: "",
        email: "",
        message: "",
      });
      alert("Your message has been sent successfully!");
    } catch (error) {
      console.error("Error submitting the form:", error);
      setLoading(false);
      alert(
        "There was an error sending your message. Please try again later or e-mail me at eliaz.bellomedrano@outlook.com"
      );
    }
  };

  return (
    <section className="contact-section">
      <div className="containerContact mx-auto">
        <h2 className="text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl mb-8 text-center">
          Contact Us
        </h2>
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="contact-form"
        >
          <div className="form-group">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              type="text"
              id="name"
              className="form-input"
              placeholder="Your Name"
              required
              onChange={handleChange}
              value={form.name}
              name="name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              value={form.email}
              onChange={handleChange}
              type="email"
              id="email"
              className="form-input"
              placeholder="Your Email"
              required
              name="email"
            />
          </div>
          <div className="form-group">
            <label htmlFor="message" className="form-label">
              Message
            </label>
            <textarea
              onChange={handleChange}
              value={form.message}
              id="message"
              className="form-input"
              rows="5"
              placeholder="Your Message"
              required
              name="message"
            ></textarea>
          </div>
          <button type="submit" className="btn-primary">
            {loading ? "Sending..." : "Send Message"}
          </button>
        </form>
      </div>
    </section>
  );
}
