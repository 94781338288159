import React, { useState } from "react";
import "./introduction.css";

const generateStars = (numberOfStars) => {
  return Array.from({ length: numberOfStars }).map((_, index) => (
    <circle
      key={index}
      cx={Math.random() * 1920}
      cy={Math.random() * 1080}
      r={Math.random() * (3 - 1) + 1}
      fill="white"
      style={{
        animation: `twinkle ${
          Math.random() * (2 - 1) + 1
        }s infinite ease-in-out alternate`,
      }}
    />
  ));
};

function Introduction() {
  const [showBio, setShowBio] = useState(false);

  const toggleBio = () => {
    setShowBio(!showBio);
  };

  return (
    <>
      <section className="relative text-white hero-section">
        <div className="overlay"></div>
        <div className="container mx-auto flex flex-row justify-between text-center h-[54rem]">
          <div className="text-container">
            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
              Eliaz Bello Medrano
            </h1>
            <div className="content p-6 rounded-lg">
              <h1 className="text-4xl font-extrabold mb-4">
                Back-end Developer
              </h1>
              <p className="mb-8">
                Hello! I'm Eliaz Bello Medrano, a passionate software developer
                with a love for creating innovative solutions. With a strong
                background in back-end development, I enjoy working on
                projects that challenge me to learn new skills and grow
                professionally.
              </p>
              <a
                href="/assets/downloadables/ResumeEliaz.pdf"
                download
                className="btn-primary"
              >
                Download CV
              </a>
              <button onClick={toggleBio} className="btn-secondary ml-4">
                Extra info
              </button>
            </div>
          </div>
        </div>
        <div className="absolute inset-0 pointer-events-none z-0">
          <svg className="w-full h-full" fill="none" viewBox="0 0 1920 1080">
            {generateStars(50)}
          </svg>
        </div>
      </section>

      {showBio && (
        <div className="bio-overlay" onClick={toggleBio}>
          <div className="bio-content" onClick={(e) => e.stopPropagation()}>
            <h2 className="text-2xl font-bold mb-4">About Me</h2>
            <p>
              Hi, I'm Eliaz Bello Medrano. From a young age, I've been intrigued
              by technology, from hardware to software. This curiosity led me to
              choose a career in web development, a field where I can merge my
              interests and skills. I am always eager to learn more and stay
              updated with the latest advancements in technology.
            </p>
            <p>
              I offer services to create websites for individuals and
              businesses, helping them establish a strong online presence. My
              goal is to deliver high-quality, efficient, and visually appealing
              websites that meet the unique needs of my clients.
            </p>
            <button onClick={toggleBio} className="mt-4 btn-primary">
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Introduction;
