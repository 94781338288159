import React from "react";
import "./App.css";
// import logo from "./assets/backgroundImage/EBMLogo.png"; // replace this with the path to your logo
// import Hero from "./components/Hero";
import Introduction from "./components/Introduction"; // Import the Introduction component
import CardSection from "./components/CardSection";
// import Experience from "./components/Experience";
import Nav from "./components/Nav";
import RotatingCardCarousel from "./components/RotatingCardCarousel";
import ContactUs from "./components/ContactUs";

function App() {
  return (
    <div className="App">
      <Nav />
      <Introduction />
      <CardSection />
      <RotatingCardCarousel />
      <ContactUs />
    </div>
  );
}

export default App;
