import React from 'react';

function Card({ title, image, description }) {
  return (
    <div className="transform transition-transform duration-300 ease-in-out hover:scale-105 hover:rotate-1 bg-gray-800 rounded-lg shadow-strong hover:shadow-xl">
      <img src={image} alt={title} className="h-60 w-full object-cover rounded-t-lg"/>
      <div className="p-4">
        <h3 className="text-lg font-bold mt-2 text-white">{title}</h3>
        <p className="text-gray-300">{description}</p>
      </div>
    </div>
  );
}

export default Card;
