import React, { useState, useEffect } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './RotatingCardCarousel.css';
const basePath = '/assets/logo/Languages'; // This could be dynamically set

const languages = [
  { name: "JavaScript", image: " logo-javascript.svg" },
  { name: "HTML", image: " html-1.svg" },
  { name: "CSS", image: " css-3.svg" },
  { name: "React", image: " react-2.svg" },
  { name: "Knex", image: " knex-1.svg" },
  { name: "Tailwind", image: " tailwind-css-2.svg" },
  { name: "Figma", image: " figma-icon.svg" },
  { name: "HyGraph & GraphQL", image: " graphql-logo-2.svg" },
  { name: "NodeJS", image: " nodejs-3.svg" },
  { name: "Adobe Creative Cloud", image: " adobe-creative-cloud-cc.svg" },
];

const chunkSize = Math.ceil(languages.length / 3);
const languagesChunks = Array.from({ length: 3 }, (_, i) => languages.slice(i * chunkSize, i * chunkSize + chunkSize));

export default function RotatingCardCarousel() {
  const [indexes, setIndexes] = useState([0, 0, 0]);
  useEffect(() => {
    const interval = setInterval(() => {
      setIndexes(prevIndexes => prevIndexes.map((prevIndex, i) => (prevIndex + 1) % languagesChunks[i].length));
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="carousel-container">
      {languagesChunks.map((languagesChunk, i) => (
        <div key={i} className="carousel">
          <TransitionGroup>
            <CSSTransition
              key={languagesChunk[indexes[i]].name}
              timeout={500}
              classNames="fade"
            >
              <div className="carousel-card">
                <img 
                  src={`${basePath}/${languagesChunk[indexes[i]].image.substring(1)}`} 
                  alt={`${languagesChunk[indexes[i]].name}`}
                />
                <h2>{languagesChunk[indexes[i]].name}</h2>
              </div>
            </CSSTransition>
          </TransitionGroup>
        </div>
      ))}
    </div>
  );
}






